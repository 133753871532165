<template>
  <NavBar />
  <div class="network-container">
    <svg ref="svg"></svg>
    <div
      ref="nodeTooltip"
      class="tooltip node"
      v-show="nodeTooltip.visible"
      :style="{
        left: nodeTooltip.x + nodeTooltipOffset.x + 'px',
        top: nodeTooltip.y + nodeTooltipOffset.y + 'px'
      }"
      @mousedown="event => startTooltipDrag(event, 'node')"
      @mouseup="() => endTooltipDrag('node')"
    >
      <span class="tooltip-close" @click="closeNodeTooltip">&times;</span>
      <div class="node-name">{{ nodeTooltip.content.name }}</div>
      <a v-if="nodeTooltip.content.link" :href="nodeTooltip.content.link" target="_blank">
        link
      </a>
      <img v-if="nodeTooltip.content.avatar" :src="nodeTooltip.content.avatar" class="node-thumbnail" />
    </div>
    <div
      ref="workTooltip"
      class="tooltip work"
      v-show="workTooltip.visible"
      :style="{
        left: workTooltip.x + workTooltipOffset.x + 'px',
        top: workTooltip.y + workTooltipOffset.y + 'px'
      }"
      @mousedown="event => startTooltipDrag(event, 'work')"
      @mouseup="() => endTooltipDrag('work')"
    >
      <span class="tooltip-close" @click="closeWorkTooltip">&times;</span>
      <img v-if="workTooltip.content.image" :src="workTooltip.content.image" class="work-thumbnail" />
      <br>
      <div class="work-title">{{ workTooltip.content.title }}</div>
      <span v-if="workTooltip.content.release_date">{{ formatDate(workTooltip.content.release_date) }}</span>
      <br>
      <a v-if="workTooltip.content.external_link" :href="workTooltip.content.external_link" target="_blank">
        listen
      </a>
      <br><br>
      <span class="preserve-whitespace" v-if="workTooltip.content.description">{{ workTooltip.content.description }}</span>
      <br><br>
      <div class="tooltip-title">creators</div>
      <div>{{ formatAsCommaSeparated(workTooltip.content.creators) }}</div>
      <br>
      <div class="tooltip-title">tags</div>
      <div>{{ formatAsCommaSeparated(workTooltip.content.tags) }}</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import axios from 'axios';
import NavBar from './NavBar.vue';

export default {
  name: 'NetworkVisualization',
  components: {
        NavBar
  },
  data() {
    return {
      nodes: [],
      links: [],
      works: [], // Added to store works data
      currentTooltipType: null,
      nodeTooltip: {
        visible: false,
        content: { name: '', bio: '' },
        x: 0,
        y: 0
      },
      workTooltip: {
        visible: false,
        content: { title: '' },
        x: 0,
        y: 0
      },
      isDraggingTooltip: false,
      tooltipDragStart: { x: 0, y: 0 },
      nodeTooltipOffset: { x: 0, y: 0 },
      workTooltipOffset: { x: 0, y: 0 },
      displayedWorks: [],
      selectedNode: null,
      selectedNodePosition: { x: 0, y: 0 },
      selectedWork: null,
      primaryColor: '#a393ab',
      hoverColor: 'yellow',
    };
  },
  mounted() {
    this.fetchData();

    document.addEventListener('mousemove', this.onGlobalMouseMove);
    document.addEventListener('mouseup', this.onGlobalMouseUp);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.onGlobalMouseMove);
    document.removeEventListener('mouseup', this.onGlobalMouseUp);
  },
  methods: {
    fetchData() {
      // Start by fetching nodes
      return axios.get('/api/nodes/')
        .then(nodesResponse => {
          this.nodes = nodesResponse.data;
          // After nodes are fetched, fetch works
          return axios.get('/api/works/');
        })
        .then(worksResponse => {
          console.log(worksResponse.data);
          this.links = this.processLinks(worksResponse.data);
          this.works = worksResponse.data;
          this.drawNetwork();
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Optionally, you could throw the error again if you want to handle it in the caller
          throw error;
        });
    },
    processLinks(works) {
      let links = [];
      works.forEach(work => {
        if (work.creators && work.creators.length > 1) {
          for (let i = 0; i < work.creators.length; i++) {
            for (let j = i + 1; j < work.creators.length; j++) {
              const sourceNode = this.nodes.find(n => n.id === work.creators[i]);
              const targetNode = this.nodes.find(n => n.id === work.creators[j]);
              if (sourceNode && targetNode) {
                links.push({ source: sourceNode, target: targetNode });
              }
            }
          }
        }
      });
      return links;
    },
    drawNetwork() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const svg = d3.select(this.$refs.svg)
        .attr('width', width)
        .attr('height', height);

      const container = svg.append('g');
      const zoom = d3.zoom()
        .scaleExtent([0.1, 10])
        .on('zoom', (event) => container.attr('transform', event.transform));
      svg.call(zoom);

      // Filter nodes to include only those that are associated with at least one work
      const nodesAssociatedWithWorks = this.nodes.filter(node =>
        this.works.some(work => work.creators.includes(node.id))
      );

      // Calculate initial positions for works based on the average positions of their creators
      this.works.forEach(work => {
        const creatorNodes = work.creators.map(creatorId => this.nodes.find(node => node.id === creatorId));
        const avgX = d3.mean(creatorNodes, d => d.x);
        const avgY = d3.mean(creatorNodes, d => d.y);
        work.x = avgX;
        work.y = avgY;
      });

      // Create virtual links between works and their associated nodes
      const workLinks = this.works.flatMap(work => 
        work.creators.map(creatorId => ({
          source: this.nodes.find(node => node.id === creatorId),
          target: work
        }))
      );

      // Combine nodes and works for the force simulation
      const combinedData = [...nodesAssociatedWithWorks, ...this.works];

      const simulation = d3.forceSimulation(combinedData)
        .force('link', d3.forceLink([...this.links, ...workLinks]).id(d => d.id).distance(50))
        .force('charge', d3.forceManyBody().strength(-200))
        .force('center', d3.forceCenter(width / 2, (height / 2) - 66)) // compensate height for NavBar height
        .force('collision', d3.forceCollide().radius(d => d.creators ? 20 : 10)) // Add collision force
        .force('work', () => {
          this.works.forEach(work => {
            const creatorNodes = work.creators.map(creatorId => this.nodes.find(node => node.id === creatorId));
            const avgX = d3.mean(creatorNodes, d => d.x);
            const avgY = d3.mean(creatorNodes, d => d.y);
            work.vx += (avgX - work.x) * 0.1; // Adjust the strength of the force as needed
            work.vy += (avgY - work.y) * 0.1; // Adjust the strength of the force as needed
          });
        });

      const link = container.append('g')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(this.links)
        .join('line')
        .attr('stroke-width', d => Math.sqrt(d.value))
        .attr('visibility', 'hidden');

      const node = container.append('g')
        .selectAll('circle')
        .data(nodesAssociatedWithWorks)
        .join('circle')
        .attr('r', 5)
        .attr('fill', this.primaryColor)
        .attr('stroke', this.primaryColor)
        .on('mouseover', (event, d) => {
          if (this.selectedNode && this.selectedNode !== d) {
            this.hideNodeTooltip();
            this.showNodeTooltip(event, d);
          } else {
            this.showNodeTooltip(event, d);
          }
        })
        .on('mouseout', this.hideNodeTooltip)
        .on('click', (event, d) => {
          this.handleNodeClick(d, event);
        })
        .call(this.drag(simulation));

      const worksGroup = container.append('g').attr('class', 'works-group');
      const work = worksGroup.selectAll('path')
        .data(this.works)
        .enter().append('path')
        .attr('d', d => {
          const size = 10;
          const halfSize = size / 2;
          return `M ${d.x},${d.y - halfSize} L ${d.x + halfSize},${d.y} L ${d.x},${d.y + halfSize} L ${d.x - halfSize},${d.y} Z`;
        })
        .attr('stroke', this.primaryColor)
        .attr('fill', 'white')
        .on('mouseover', (event, work) => {
          this.showWorkTooltip(event, work);
          this.showLinksForWork(work);
        })
        .on('mouseout', () => {
          this.hideWorkTooltip();
          this.hideLinksForWork();
        })
        .on('click', (event, work) => {
          this.handleWorkClick(work, event);
        });

      simulation.on('tick', () => {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y);

        node
          .attr('cx', d => d.x)
          .attr('cy', d => d.y);

        work
          .attr('d', d => {
            const size = 15;
            const halfSize = size / 2;
            return `M ${d.x},${d.y - halfSize} L ${d.x + halfSize},${d.y} L ${d.x},${d.y + halfSize} L ${d.x - halfSize},${d.y} Z`;
          });
      });
    },
    drag(simulation) {
      function dragstarted(event) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
    },
    showNodeTooltip(event, node) {
      this.nodeTooltip.visible = true;
      this.nodeTooltip.content = {
        ...node,
      };
      this.$nextTick(() => {
        // Set the tooltip position to the top left of the viewport
        // You might want to add some margin for aesthetic spacing
        const marginLeft = 20; // Margin from the left edge of the viewport
        const marginTop = 20; // Margin from the top edge of the viewport

        this.nodeTooltip.x = marginLeft;
        this.nodeTooltip.y = marginTop;
      });

      // Highlight works where the hovered node is a creator
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');
      worksGroup.selectAll('path')
        .data(this.works.filter(work => work.creators.includes(node.id)), d => d.id)
        .attr('fill', this.hoverColor);
    },
    hideNodeTooltip() {
      if (!this.selectedNode) {
        this.nodeTooltip.visible = false;
      }

      // Reset the fill color of all works to white (or the original color)
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');
      worksGroup.selectAll('path')
        .attr('fill', 'white'); // Reset fill color to white for all works
    },
    hideWorkTooltip() {
      if (!this.selectedWork) {
        this.workTooltip.visible = false;
      }
    },
    showWorkTooltip(event, work) {
      // Find the creator names by matching the creator IDs from the work with the nodes.
      const creatorNames = work.creators.map(creatorId => {
        const node = this.nodes.find(node => node.id === creatorId);
        return node ? node.name : null;
      }).filter(name => name !== null) // Filter out any nulls in case a node wasn't found
      .sort(); // Sort the names alphabetically

      this.workTooltip.visible = true;
      this.workTooltip.content = {
        ...work,
        creators: creatorNames, // Replace the IDs with the actual names
      };
      
      this.$nextTick(() => {
        const tooltipElement = this.$refs.workTooltip;
        const tooltipWidth = tooltipElement.offsetWidth;

        // Set the tooltip position to the top right of the viewport
        // You might want to add some margin for aesthetic spacing
        const marginRight = 20; // Margin from the right edge of the viewport
        const marginTop = 20; // Margin from the top edge of the viewport

        this.workTooltip.x = window.innerWidth - tooltipWidth - marginRight;
        this.workTooltip.y = marginTop;
      });
    },
    handleNodeClick(node, event) {
      this.selectedNode = node;
      this.showNodeTooltip(event, node);
      this.selectedNodePosition = { x: event.x, y: event.y };
    },
    handleWorkClick(work, event) {
      this.selectedWork = work;
      this.showWorkTooltip(event, work);
    },
    updateWorkPositions() {
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');

      worksGroup.selectAll('rect')
        .attr('x', work => {
          // Calculate the average x position of creator Nodes
          const avgX = work.creators.reduce((acc, creatorId) => {
            const node = this.nodes.find(node => node.id === creatorId);
            return acc + (node ? node.x : 0);
          }, 0) / work.creators.length;
          return avgX - 5; // Adjusting for Work size to center it
        })
        .attr('y', work => {
          // Calculate the average y position of creator Nodes
          const avgY = work.creators.reduce((acc, creatorId) => {
            const node = this.nodes.find(node => node.id === creatorId);
            return acc + (node ? node.y : 0);
          }, 0) / work.creators.length;
          return avgY - 5; // Adjusting for Work size to center it
        });
    },
    showLinksForWork(work) {
      const linkedNodeIds = new Set(work.creators);
      this.links.forEach(link => {
        link.visible = linkedNodeIds.has(link.source.id) && linkedNodeIds.has(link.target.id);
      });
      this.updateLinkVisibility();

      // Dim all nodes and works by setting their opacity to 0.25
      d3.select(this.$refs.svg).selectAll('circle').style('opacity', 0.25);
      d3.select(this.$refs.svg).selectAll('.works-group rect').style('opacity', function(d) {
        // Assuming each 'rect' element has a data attribute 'data-work-id' that stores the work's ID
        return d.id === work.id ? 1 : 0.25; // Only dim works that are not the hovered-over work
      });

      // Highlight the nodes associated with the selected work by setting their opacity back to 1
      d3.select(this.$refs.svg).selectAll('circle')
        .filter(d => linkedNodeIds.has(d.id))
        .style('opacity', 1);

      // Update node fill color if it's part of the link
      d3.select(this.$refs.svg).selectAll('circle')
        .attr('fill', d => linkedNodeIds.has(d.id) ? this.hoverColor : this.primaryColor);
    },
    hideLinksForWork() {
      this.links.forEach(link => {
        link.visible = false;
      });
      this.updateLinkVisibility();

      // Reset the opacity of all nodes and works back to 1
      d3.select(this.$refs.svg).selectAll('circle').style('opacity', 1);
      d3.select(this.$refs.svg).selectAll('.works-group rect').style('opacity', 1);

      // Reset node fill color to its original color
      d3.select(this.$refs.svg).selectAll('circle')
        .attr('fill', this.primaryColor);
    },
    updateLinkVisibility() {
      const svg = d3.select(this.$refs.svg);
      svg.selectAll('line')
        .attr('visibility', d => d.visible ? 'visible' : 'hidden');
    },
    closeWorkTooltip(event) {
      event.stopPropagation(); // Prevent the click from closing other elements
      this.workTooltip.visible = false;
      this.hideLinksForWork();
      this.workTooltipOffset = { x: 0, y: 0 };
      this.selectedWork = null; // Reset selected work
    },
    closeNodeTooltip(event) {
      event.stopPropagation(); // Prevent the click from closing other elements
      this.nodeTooltip.visible = false;
      // Reset the node tooltip offset
      this.nodeTooltipOffset = { x: 0, y: 0 };
      this.selectedNode = null;
    },
    startTooltipDrag(event, tooltipType) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingTooltip = true;
      this.currentTooltipType = tooltipType; // Store the current tooltip type being dragged
      this.tooltipDragStart.x = event.clientX - this[`${tooltipType}TooltipOffset`].x;
      this.tooltipDragStart.y = event.clientY - this[`${tooltipType}TooltipOffset`].y;
    },
    onTooltipDrag(event) {
      if (this.isDraggingTooltip) {
        const tooltipType = this.currentTooltipType; // Use the stored tooltip type
        this[`${tooltipType}TooltipOffset`].x = event.clientX - this.tooltipDragStart.x;
        this[`${tooltipType}TooltipOffset`].y = event.clientY - this.tooltipDragStart.y;
      }
    },
    endTooltipDrag() {
      if (this.isDraggingTooltip) {
        this.isDraggingTooltip = false;
        this.currentTooltipType = null; // Reset the current tooltip type
      }
    },
    onGlobalMouseMove(event) {
      if (this.isDraggingTooltip) {
        this.onTooltipDrag(event);
      }
    },
    onGlobalMouseUp() {
      if (this.isDraggingTooltip) {
        this.endTooltipDrag();
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatAsCommaSeparated(list) {
      if (Array.isArray(list) && list.length) {
        return list.join(', ');
      }
      return ''; // Return an empty string if the input is not a valid array
    },
  }
};
</script>

<style scoped>
body {
  font-family: Arial;
  color: #a393ab;
}
.network-container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 66px);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

svg {
  display: block;
  background-color: #fbfbfb;
}

.tooltip {
  word-wrap: break-word;
  position: absolute;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 1;
  font-size: 14px;
  text-align: left;
  padding: 10px;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  pointer-events: auto;
  transition: opacity 0.3s;
  z-index: 1000;
}

.tooltip p {
  margin: 0;
}

.tooltip-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  cursor: pointer;
  z-index: 1000;
  pointer-events: all;
}

.tooltip-title {
  color: #a393ab;
}

.work-thumbnail {
  max-width: 300px; /* Adjust size as needed */
  max-height: 300px; /* Adjust size as needed */
  margin: 10px 0;
}
.node-thumbnail {
  max-width: 200px; /* Adjust size as needed */
  max-height: 300px; /* Adjust size as needed */
  margin: 10px 0;
  border: 1px solid #a393ab;
}

.node {
  max-width: 200px;
}

.work {
  max-width: 300px;
}

.node-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.work-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
