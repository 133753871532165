<template>
    <div>
      <NavBar />
      <div class="text-container">
        <div class="text">
            <p>
                A constellated artist organism, meeting in varying configurations to explore sound and make recordings
            </p>
            <p>
                Full library available upon request for use in creative projects; commissioned works and live bookings also welcomed
            </p>
            <p>
                <a href="mailto:who@localgroup.art">who@localgroup.art</a>
            </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // Import NavBar component
  import NavBar from './NavBar.vue';
  
  export default {
    name: 'InfoDisplay',
    components: {
      NavBar
    },
  };
  </script>
  
  <style scoped>
  .text-container {
    display: flex;
    justify-content: center;
    text-align: left;
  }
  
  .text {
    max-width: 40%;
  }

  @media (max-width: 768px) {
    .text {
        max-width: 65%;
    }
}
  </style>