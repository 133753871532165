import { createRouter, createWebHistory } from 'vue-router';
import NodesList from '@/components/NodesList';
import NodeDetails from '@/components/NodeDetails';
import WorksList from '@/components/WorksList';
import WorkDetails from '@/components/WorkDetails';
import NetworkVisualizationWrapper from '@/components/NetworkVisualizationWrapper.vue';

import UserLogin from '@/components/UserLogin.vue';
import WorkFormComponent from '@/components/WorkFormComponent.vue';
import NodeFormComponent from '@/components/NodeFormComponent.vue';
import SelectEditItem from '@/components/SelectEditItem.vue';
import InfoDisplay from '@/components/InfoDisplay.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: UserLogin
  },
  {
    path: '/nodes',
    name: 'NodesList',
    component: NodesList,
    meta: { requiresAuth: true }
  },
  {
    path: '/nodes/:id',
    name: 'NodeDetails',
    component: NodeDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  // Add route for adding a new node
  {
    path: '/nodes/add',
    name: 'AddNode',
    component: NodeFormComponent,
    meta: { requiresAuth: true }
  },
  // Add route for editing an existing node
  {
    path: '/nodes/edit/:id',
    name: 'EditNode',
    component: NodeFormComponent,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/works',
    name: 'WorksList',
    component: WorksList,
    meta: { requiresAuth: true }
  },
  {
    path: '/works/:id',
    name: 'WorkDetails',
    component: WorkDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  // Add route for adding a new work
  {
    path: '/works/add',
    name: 'AddWork',
    component: WorkFormComponent,
    meta: { requiresAuth: true }
  },
  // Add route for editing an existing work
  {
    path: '/works/edit/:id',
    name: 'EditWork',
    component: WorkFormComponent,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/select-edit-item',
    name: 'SelectEditItem',
    component: SelectEditItem,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'Network',
    component: NetworkVisualizationWrapper
  },
  {
    path: '/releases',
    name: 'WorksDisplay',
    component: () => import('@/components/WorksDisplay.vue')
  },
  {
    path: '/info',
    name: 'InfoDisplay',
    component: InfoDisplay
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem('authToken');

  if (requiresAuth && !isAuthenticated) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
});

export default router;

