<template>
  <div>
    <h2>Works</h2>
    <ul>
      <li v-for="work in works" :key="work.id">
        <router-link :to="{ name: 'WorkDetails', params: { id: work.id }}">{{ work.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WorksList',
  data() {
    return {
      works: [],
    };
  },
  mounted() {
    axios.get('/api/works/')
      .then(response => {
        this.works = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>
