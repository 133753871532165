<template>
  <div v-if="node">
    <h2>{{ node.name }}</h2>
    <p>{{ node.bio }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NodeDetails',
  data() {
    return {
      node: null,
    };
  },
  mounted() {
    axios.get(`/api/nodes/${this.$route.params.id}/`)
      .then(response => {
        this.node = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>

