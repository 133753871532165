<template>
    <div>
      <form @submit.prevent="login">
        <input type="text" v-model="username" placeholder="Username" required>
        <input type="password" v-model="password" placeholder="Password" required>
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      login() {
        axios.post('/api/api-token-auth/', {
          username: this.username,
          password: this.password
        })
        .then(response => {
          // Save the token in local storage or Vuex store
          localStorage.setItem('authToken', response.data.token);
          // Redirect to the route the user was trying to access or to a default route
          const redirect = this.$route.query.redirect || '/nodes';
          this.$router.push(redirect);
        })
        .catch(error => {
          console.error('Authentication failed:', error);
        });
      }
    }
  }
  </script>