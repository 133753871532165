<template>
    <div>
      <h2>Select an Item to Edit</h2>
      <div>
        <h3>Works</h3>
        <select v-model="selectedWork" @change="goToEditWork">
          <option disabled value="">Please select one</option>
          <option v-for="work in works" :key="work.id" :value="work.id">{{ work.title }}</option>
        </select>
      </div>
      <div>
        <h3>Nodes</h3>
        <select v-model="selectedNode" @change="goToEditNode">
          <option disabled value="">Please select one</option>
          <option v-for="node in nodes" :key="node.id" :value="node.id">{{ node.name }}</option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        works: [],
        nodes: [],
        selectedWork: '',
        selectedNode: '',
      };
    },
    mounted() {
      this.fetchWorks();
      this.fetchNodes();
    },
    methods: {
      async fetchWorks() {
        try {
          const response = await axios.get('/api/works/');
          this.works = response.data;
        } catch (error) {
          console.error('Error fetching works:', error);
        }
      },
      async fetchNodes() {
        try {
          const response = await axios.get('/api/nodes/');
          this.nodes = response.data;
        } catch (error) {
          console.error('Error fetching nodes:', error);
        }
      },
      goToEditWork() {
        if (this.selectedWork) {
          this.$router.push(`/works/edit/${this.selectedWork}`);
        }
      },
      goToEditNode() {
        if (this.selectedNode) {
          this.$router.push(`/nodes/edit/${this.selectedNode}`);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>