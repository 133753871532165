<template>
    <div class="node-form">
      <h2>{{ nodeId ? 'Edit Node' : 'Add Node' }}</h2>
      <button type="button" @click="deleteNode" v-if="nodeId">Delete Node</button>
      <form @submit.prevent="submitForm">
        <div>
          <label for="name">Name:</label>
          <input id="name" v-model="node.name" type="text" required>
        </div>
        <div>
          <label for="link">Link:</label>
          <input id="link" v-model="node.link" type="url">
        </div>
        <div>
          <label for="bio">Bio:</label>
          <textarea id="bio" v-model="node.bio"></textarea>
        </div>
        <div v-if="node.avatar">
          <img :src="node.avatar" alt="Node Avatar" style="max-width: 200px; max-height: 200px;">
        </div>
        <div>
          <label for="avatar">Avatar:</label>
          <input type="file" id="avatar" @change="handleFileUpload">
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        nodeId: this.$route.params.id, // For edit mode
        node: {
          name: '',
          bio: '',
          avatar: '', // Assuming avatar is handled via URL for simplicity
          link: '', // Default link or empty string if you prefer
        },
        selectedFile: null,
      };
    },
    mounted() {
      if (this.nodeId) {
        this.fetchNode();
      }
    },
    methods: {
      async fetchNode() {
        const response = await axios.get(`/api/nodes/${this.nodeId}`);
        this.node = response.data;
      },
      handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
      },
      async deleteNode() {
        if (!confirm("Are you sure you want to delete this node?")) return;
        try {
          await axios.delete(`/api/nodes/${this.nodeId}/`);
          this.$router.push('/nodes'); // Redirect after successful deletion
        } catch (error) {
          console.error('There was an issue deleting the node:', error);
        }
      },
      async submitForm() {
        const formData = new FormData();
        formData.append('name', this.node.name);
        formData.append('bio', this.node.bio);
        formData.append('link', this.node.link); // Handle link attribute
        if (this.selectedFile) {
            formData.append('avatar', this.selectedFile, this.selectedFile.name);
        }

        const config = {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        };

        const method = this.nodeId ? 'put' : 'post';
        const url = this.nodeId ? `/api/nodes/${this.nodeId}/` : '/api/nodes/';
        await axios[method](url, formData, config);
        this.$router.push('/nodes'); // Redirect after submit
      },
    },
  }
  </script>
  
  <style scoped>
  .node-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.node-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.node-form form > div {
  margin-bottom: 15px;
}

.node-form label {
  display: block;
  margin-bottom: 5px;
}

.node-form input[type="text"],
.node-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.node-form button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.node-form button[type="submit"]:hover {
  background-color: #0056b3;
}
</style>