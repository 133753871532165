<template>
    <nav class="navbar">
      <div class="container">
        <!-- Replace "Local Group" with an image -->
        <a class="navbar-brand" href="/">
          <img :src="logoImage" alt="Local Group Logo">
        </a>
        <ul class="navbar-nav">
          <li><router-link to="/releases" active-class="active-link">Releases</router-link></li>
          <li><router-link to="/info" active-class="active-link">Info</router-link></li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  // Import the logo image
import logoImage from '@/assets/ellipse_logo_violet.png';

export default {
    name: 'NavBar',
    data() {
      return {
        logoImage, // Make the imported image available in the template
      };
    },
  };
  </script>
  
  <style scoped>
  .active-link {
    font-weight: bold;
  }

  .navbar {
    padding: 10px 30px 0 10px;
  }

  .navbar a {
    /* color: #9a9a9a; */
    color: #a393ab;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    text-decoration: none;
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-nav li {
    margin-left: 20px;
  }
  
  .navbar-nav li a {
    text-decoration: none;
  }

  /* Add styles for the logo image if necessary */
  .navbar-brand img {
    height: 50px; /* Adjust the height as needed */
  }
  </style>