<template>
    <div class="work-form">
      <h2>{{ workId ? 'Edit Work' : 'Add Work' }}</h2>
      <button type="button" @click="deleteWork" v-if="workId">Delete Work</button>
      <form @submit.prevent="submitForm">
        <div>
          <label for="title">Title:</label>
          <input id="title" v-model="work.title" type="text" required>
        </div>
        <div>
          <label for="release_date">Release Date:</label>
          <input id="release_date" v-model="work.release_date" type="date">
        </div>
        <div v-if="work.image">
          <img :src="work.image" alt="Work Image" style="max-width: 200px; max-height: 200px;">
        </div>
        <div>
          <label for="image">Work Image:</label>
          <input type="file" id="image" @change="handleFileUpload">
        </div>
        <div>
          <label for="description">Description:</label>
          <textarea id="description" v-model="work.description"></textarea>
        </div>
        <div>
            <label for="creators">Creators:</label>
            <!-- Display Selected Creators -->
            <div v-for="creatorId in work.creators" :key="creatorId" class="selected-creator">
            <span>{{ getNodeName(creatorId) }}</span>
            <button type="button" @click="removeCreator(creatorId)">Remove</button>
            </div>
            <!-- Autocomplete Input for Adding Creators -->
            <input 
            type="text" 
            v-model="searchQuery" 
            placeholder="Start typing a creator's name..."
            >
            <ul class="autocomplete-results" v-if="filteredNodes.length">
            <li 
                v-for="node in filteredNodes" 
                :key="node.id" 
                @click="addCreator(node.id)"
            >
                {{ node.name }}
            </li>
            </ul>
        </div>
        <div>
          <label for="external_link">External Link:</label>
          <input id="external_link" v-model="work.external_link" type="url">
        </div>
        <div>
          <label for="tags">Tags:</label>
          <input id="tags" v-model="work.tags" type="text" placeholder="Enter tags separated by commas">
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
import axios from 'axios';

export default {
  data() {
    return {
      workId: this.$route.params.id,
      work: {
        title: '',
        description: '',
        creators: [],
        external_link: '',
        tags: '',
        release_date: '',
      },
      selectedFile: null,
      nodes: [],
      searchQuery: '',
    };
  },
  mounted() {
    this.fetchNodes();
    if (this.workId) {
      this.fetchWork();
    }
  },
  computed: {
    filteredNodes() {
        if (this.searchQuery.length > 1) { // Adjust the number as needed
        return this.nodes.filter(node => 
            node.name.toLowerCase().startsWith(this.searchQuery.toLowerCase())
        );
        }
        return []; // Return an empty array if the condition is not met
    }
  },
  methods: {
    async fetchNodes() {
      try {
        const response = await axios.get('/api/nodes/');
        this.nodes = response.data;
      } catch (error) {
        console.error('There was an issue fetching the nodes:', error);
      }
    },
    async fetchWork() {
      try {
        const response = await axios.get(`/api/works/${this.workId}`);
        this.work = response.data;
        console.log('hi')
        console.log(this.work.tags)
        if (this.work.tags && Array.isArray(this.work.tags)) {
          this.work.tags = this.work.tags.join(', ');
        }
      } catch (error) {
        console.error('There was an issue fetching the work:', error);
      }
    },
      handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    addCreator(creatorId) {
        if (!this.work.creators.includes(creatorId)) {
        this.work.creators.push(creatorId);
        }
        this.searchQuery = ''; // Clear the search query after adding a creator
    },
    removeCreator(creatorId) {
        this.work.creators = this.work.creators.filter(id => id !== creatorId);
    },
    getNodeName(creatorId) {
      const node = this.nodes.find(node => node.id === creatorId);
      return node ? node.name : 'Unknown';
    },
    async deleteWork() {
      if (!confirm("Are you sure you want to delete this work?")) return;
      try {
        await axios.delete(`/api/works/${this.workId}/`);
        this.$router.push('/works'); // Redirect after successful deletion
      } catch (error) {
        console.error('There was an issue deleting the work:', error);
      }
    },
    async submitForm() {
        const formData = new FormData();
        formData.append('title', this.work.title);
        formData.append('description', this.work.description);
        if (this.work.external_link) {
            formData.append('external_link', this.work.external_link);
        }
        if (this.work.release_date) {
            formData.append('release_date', this.work.release_date);
        }
        if (this.work.tags) {
            const tagsArray = this.work.tags.split(',').map(tag => tag.trim());
            const tagsJsonString = JSON.stringify(tagsArray);
            console.log("Tags JSON String:", tagsJsonString); // Debugging statement
            formData.append('tags', tagsJsonString);
        }
        if (this.work.creators && this.work.creators.length) {
            this.work.creators.forEach(creator => formData.append('creators', creator));
        }
        if (this.selectedFile) {
            formData.append('image', this.selectedFile, this.selectedFile.name);
        }

        const config = {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        };

        console.log("Form Data Entries:", Array.from(formData.entries())); // Debugging statement

        try {
            const method = this.workId ? 'put' : 'post';
            const url = this.workId ? `/api/works/${this.workId}/` : '/api/works/';
            await axios[method](url, formData, config);
            this.$router.push('/works'); // Redirect after submit
        } catch (error) {
            console.error('There was an issue submitting the form:', error);
        }
        },
  },
}
</script>
  
<style scoped>
.work-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.work-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.work-form form > div {
  margin-bottom: 15px;
}

.work-form label {
  display: block;
  margin-bottom: 5px;
}

.work-form input[type="text"],
.work-form textarea,
.work-form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.work-form button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.work-form button[type="submit"]:hover {
  background-color: #0056b3;
}
</style>