<template>
  <div id="app">
    <!-- 
    <nav>
      <router-link to="/nodes">Nodes</router-link> |
      <router-link to="/works">Works</router-link>
    </nav>
    -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #725282; */
  color: #888888;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fbfbfb;
}

a {
  color: #9a9a9a;
}

h1, h2 {
  color: #725282;
}
</style>