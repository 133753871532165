<template>
    <component :is="selectedComponent"></component>
  </template>
  
  <script>
  import NetworkVisualization from './NetworkVisualization.vue';
  import NetworkVisualizationMobile from './NetworkVisualizationMobile.vue';
  
  export default {
    data() {
      return {
        selectedComponent: null,
      };
    },
    created() {
      this.selectComponent();
      window.addEventListener('resize', this.selectComponent);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.selectComponent);
    },
    methods: {
      selectComponent() {
        this.selectedComponent = window.innerWidth <= 768 ? NetworkVisualizationMobile : NetworkVisualization;
      },
    },
  };
  </script>