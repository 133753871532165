<template>
  <NavBar />
  <div class="network-container">
    <svg ref="svg"></svg>
    <div
      ref="nodeTooltip"
      class="node-tooltip-bar"
      v-show="nodeTooltip.visible"
    >
      <img v-if="nodeTooltip.content.avatar" :src="nodeTooltip.content.avatar" class="node-thumbnail-bar" />
      <div class="node-tooltip-content">
        <div class="node-name">{{ nodeTooltip.content.name }}</div>
        <div v-if="nodeTooltip.content.description" class="node-description">{{ nodeTooltip.content.description }}</div>
        <a v-if="nodeTooltip.content.link" :href="nodeTooltip.content.link" target="_blank" class="node-link">link</a>
      </div>
      <span class="tooltip-close" @click="closeNodeTooltip">&times;</span>
    </div>
    <div
      ref="workTooltip"
      class="tooltip work"
      v-show="workTooltip.visible"
    >
      <span class="tooltip-close" @click="closeWorkTooltip">&times;</span>
      <img v-if="workTooltip.content.image" :src="workTooltip.content.image" class="work-thumbnail" />
      <div class="work-tooltip-content">
        <div class="work-title">{{ workTooltip.content.title }}</div>
        <span v-if="workTooltip.content.release_date">{{ formatDate(workTooltip.content.release_date) }}</span>
        <a v-if="workTooltip.content.external_link" :href="workTooltip.content.external_link" target="_blank">listen</a>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import axios from 'axios';
import NavBar from './NavBar.vue';

export default {
  name: 'NetworkVisualizationMobile',
  components: {
        NavBar
  },
  data() {
    return {
      nodes: [],
      links: [],
      works: [], // Added to store works data
      currentTooltipType: null,
      nodeTooltip: {
        visible: false,
        content: { name: '', bio: '' },
        x: 0,
        y: 0
      },
      workTooltip: {
        visible: false,
        content: { title: '' },
        x: 0,
        y: 0
      },
      nodeTooltipOffset: { x: 0, y: 0 },
      workTooltipOffset: { x: 0, y: 0 },
      displayedWorks: [],
      selectedNode: null,
      selectedNodePosition: { x: 0, y: 0 },
      selectedWork: null,
      primaryColor: '#a393ab',
      hoverColor: 'yellow',
    };
  },
  mounted() {
    this.fetchData();

    document.addEventListener('mousemove', this.onGlobalMouseMove);
    document.addEventListener('mouseup', this.onGlobalMouseUp);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.onGlobalMouseMove);
    document.removeEventListener('mouseup', this.onGlobalMouseUp);
  },
  methods: {
    fetchData() {
      // Start by fetching nodes
      return axios.get('/api/nodes/')
        .then(nodesResponse => {
          this.nodes = nodesResponse.data;
          // After nodes are fetched, fetch works
          return axios.get('/api/works/');
        })
        .then(worksResponse => {
          console.log(worksResponse.data);
          this.links = this.processLinks(worksResponse.data);
          this.works = worksResponse.data;
          this.drawNetwork();
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Optionally, you could throw the error again if you want to handle it in the caller
          throw error;
        });
    },
    processLinks(works) {
      let links = [];
      works.forEach(work => {
        if (work.creators && work.creators.length > 1) {
          for (let i = 0; i < work.creators.length; i++) {
            for (let j = i + 1; j < work.creators.length; j++) {
              const sourceNode = this.nodes.find(n => n.id === work.creators[i]);
              const targetNode = this.nodes.find(n => n.id === work.creators[j]);
              if (sourceNode && targetNode) {
                links.push({ source: sourceNode, target: targetNode });
              }
            }
          }
        }
      });
      return links;
    },
    drawNetwork() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const svg = d3.select(this.$refs.svg)
        .attr('width', width)
        .attr('height', height);

      const container = svg.append('g');
      const zoom = d3.zoom()
        .scaleExtent([0.1, 10])
        .on('zoom', (event) => container.attr('transform', event.transform));
      svg.call(zoom);

      // Filter nodes to include only those that are associated with at least one work
      const nodesAssociatedWithWorks = this.nodes.filter(node =>
        this.works.some(work => work.creators.includes(node.id))
      );

      // Combine nodes and works for the force simulation
      const combinedData = [...nodesAssociatedWithWorks, ...this.works];

      // Create links between works and their creator nodes
      const workLinks = this.works.flatMap(work => 
        work.creators.map(creatorId => ({
          source: work,
          target: this.nodes.find(node => node.id === creatorId)
        }))
      );

      const simulation = d3.forceSimulation(combinedData)
        .force('link', d3.forceLink([...this.links, ...workLinks]).id(d => d.id).distance(30))
        .force('charge', d3.forceManyBody().strength(-200))
        .force('center', d3.forceCenter(width / 2, height / 2))
        .force('collision', d3.forceCollide().radius(d => d.creators ? 15 : 5))
        .force('work', () => {
          this.works.forEach(work => {
            const creatorNodes = work.creators.map(creatorId => this.nodes.find(node => node.id === creatorId));
            const avgX = d3.mean(creatorNodes, d => d.x);
            const avgY = d3.mean(creatorNodes, d => d.y);
            work.vx += (avgX - work.x) * 0.1; // Adjust the strength of the force as needed
            work.vy += (avgY - work.y) * 0.1; // Adjust the strength of the force as needed
          });
        });

      const link = container.append('g')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(this.links)
        .join('line')
        .attr('stroke-width', d => Math.sqrt(d.value))
        .attr('visibility', 'hidden');

      const node = container.append('g')
        .selectAll('circle')
        .data(nodesAssociatedWithWorks)
        .join('circle')
        .attr('r', 5) // Keep the original size
        .attr('fill', this.primaryColor)
        .attr('stroke', this.primaryColor)
        .on('click', (event, d) => {
          this.handleNodeClick(d, event);
        });

      const worksGroup = container.append('g').attr('class', 'works-group');
      const work = worksGroup.selectAll('path')
        .data(this.works)
        .enter().append('path')
        .attr('d', d => {
          const size = 10; // Keep the original size
          const halfSize = size / 2;
          return `M ${d.x},${d.y - halfSize} L ${d.x + halfSize},${d.y} L ${d.x},${d.y + halfSize} L ${d.x - halfSize},${d.y} Z`;
        })
        .attr('stroke', this.primaryColor)
        .attr('fill', 'white')
        .on('click', (event, work) => {
          this.handleWorkClick(work, event);
        });

      simulation.on('tick', () => {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y);

        node
          .attr('cx', d => d.x)
          .attr('cy', d => d.y);

        work
          .attr('d', d => {
            const size = 15;
            const halfSize = size / 2;
            return `M ${d.x},${d.y - halfSize} L ${d.x + halfSize},${d.y} L ${d.x},${d.y + halfSize} L ${d.x - halfSize},${d.y} Z`;
          });
      });
    },
    showNodeTooltip(event, node) {
      this.nodeTooltip.visible = true;
      this.nodeTooltip.content = {
        ...node,
      };
      
      // Position the tooltip at the bottom of the screen
      const tooltipElement = this.$refs.nodeTooltip;
      if (tooltipElement) {
        tooltipElement.style.bottom = '10px';
        tooltipElement.style.left = '10px';
        tooltipElement.style.right = '10px';
      }

      // Highlight works where the selected node is a creator
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');
      worksGroup.selectAll('path')
        .data(this.works.filter(work => work.creators.includes(node.id)), d => d.id)
        .attr('fill', this.hoverColor);
    },
    hideNodeTooltip() {
      if (!this.selectedNode) {
        this.nodeTooltip.visible = false;
      }

      // Reset the fill color of all works to white (or the original color)
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');
      worksGroup.selectAll('path')
        .attr('fill', 'white'); // Reset fill color to white for all works
    },
    hideWorkTooltip() {
      if (!this.selectedWork) {
        this.workTooltip.visible = false;
      }
    },
    showWorkTooltip(event, work) {
      const creatorNames = work.creators.map(creatorId => {
        const node = this.nodes.find(node => node.id === creatorId);
        return node ? node.name : null;
      }).filter(name => name !== null);

      this.workTooltip.visible = true;
      this.workTooltip.content = {
        ...work,
        creators: creatorNames,
      };
      
      // Position the tooltip at the top of the screen
      const tooltipElement = this.$refs.workTooltip;
      if (tooltipElement) {
        tooltipElement.style.top = '76px'; // Adjust based on your NavBar height
        tooltipElement.style.left = '10px';
        tooltipElement.style.right = '10px';
      }
    },
    handleNodeClick(node, event) {
      this.selectedNode = node;
      this.showNodeTooltip(event, node);
      this.selectedNodePosition = { x: event.x, y: event.y };
    },
    handleWorkClick(work, event) {
      this.selectedWork = work;
      this.showWorkTooltip(event, work);
      this.showLinksForWork(work);
    },
    updateWorkPositions() {
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');

      worksGroup.selectAll('rect')
        .attr('x', work => {
          // Calculate the average x position of creator Nodes
          const avgX = work.creators.reduce((acc, creatorId) => {
            const node = this.nodes.find(node => node.id === creatorId);
            return acc + (node ? node.x : 0);
          }, 0) / work.creators.length;
          return avgX - 5; // Adjusting for Work size to center it
        })
        .attr('y', work => {
          // Calculate the average y position of creator Nodes
          const avgY = work.creators.reduce((acc, creatorId) => {
            const node = this.nodes.find(node => node.id === creatorId);
            return acc + (node ? node.y : 0);
          }, 0) / work.creators.length;
          return avgY - 5; // Adjusting for Work size to center it
        });
    },
    showLinksForWork(work) {
      const linkedNodeIds = new Set(work.creators);
      this.links.forEach(link => {
        link.visible = linkedNodeIds.has(link.source.id) && linkedNodeIds.has(link.target.id);
      });
      this.updateLinkVisibility();

      // Dim all nodes and works by setting their opacity to 0.25
      d3.select(this.$refs.svg).selectAll('circle').style('opacity', 0.25);
      d3.select(this.$refs.svg).selectAll('.works-group rect').style('opacity', function(d) {
        // Assuming each 'rect' element has a data attribute 'data-work-id' that stores the work's ID
        return d.id === work.id ? 1 : 0.25; // Only dim works that are not the hovered-over work
      });

      // Highlight the nodes associated with the selected work by setting their opacity back to 1
      d3.select(this.$refs.svg).selectAll('circle')
        .filter(d => linkedNodeIds.has(d.id))
        .style('opacity', 1);

      // Update node fill color if it's part of the link
      d3.select(this.$refs.svg).selectAll('circle')
        .attr('fill', d => linkedNodeIds.has(d.id) ? this.hoverColor : this.primaryColor);
    },
    hideLinksForWork() {
      this.links.forEach(link => {
        link.visible = false;
      });
      this.updateLinkVisibility();

      // Reset the opacity of all nodes and works back to 1
      d3.select(this.$refs.svg).selectAll('circle').style('opacity', 1);
      d3.select(this.$refs.svg).selectAll('.works-group rect').style('opacity', 1);

      // Reset node fill color to its original color
      d3.select(this.$refs.svg).selectAll('circle')
        .attr('fill', this.primaryColor);
    },
    updateLinkVisibility() {
      const svg = d3.select(this.$refs.svg);
      svg.selectAll('line')
        .attr('visibility', d => d.visible ? 'visible' : 'hidden');
    },
    closeWorkTooltip(event) {
      event.stopPropagation(); // Prevent the click from closing other elements
      this.workTooltip.visible = false;
      this.hideLinksForWork();
      this.workTooltipOffset = { x: 0, y: 0 };
      this.selectedWork = null; // Reset selected work
    },
    closeNodeTooltip(event) {
      event.stopPropagation(); // Prevent the click from closing other elements
      this.nodeTooltip.visible = false;
      // Reset the node tooltip offset
      this.nodeTooltipOffset = { x: 0, y: 0 };
      this.selectedNode = null;
      // Reset the fill color of all works to white (or the original color)
      const worksGroup = d3.select(this.$refs.svg).select('.works-group');
      worksGroup.selectAll('path')
        .attr('fill', 'white'); // Reset fill color to white for all works
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatAsCommaSeparated(list) {
      if (Array.isArray(list) && list.length) {
        return list.join(', ');
      }
      return ''; // Return an empty string if the input is not a valid array
    },
  }
};
</script>

<style scoped>
body {
  font-family: Arial;
  color: #a393ab;
}
.network-container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 66px);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

svg {
  display: block;
  background-color: #fbfbfb;
}

.tooltip.work {
  position: fixed;
  top: 66px; /* Adjust based on the actual height of your NavBar */
  left: 0;
  right: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid #d4d4d5;
  z-index: 1000;
  box-sizing: border-box;
}

.work-thumbnail {
  max-width: 100px; /* Adjust size as needed */
  max-height: 100px; /* Adjust size as needed */
  margin-right: 10px;
}

.work-tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.work-title {
  font-weight: bold;
}

.tooltip p {
  margin: 0;
}

.tooltip-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  cursor: pointer;
  z-index: 1000;
  pointer-events: all;
}

.tooltip-title {
  color: #a393ab;
}

.node-tooltip-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 40%;
  max-width: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding: 10px;
  border-top: 1px solid #d4d4d5;
  z-index: 1000;
}

.node-thumbnail-bar {
  max-width: 50px; /* Adjust size as needed */
  max-height: 50px; /* Adjust size as needed */
  margin-right: 10px;
}

.node-tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.node-name {
  font-weight: bold;
}

.node-description {
  font-size: 14px;
}

.node-link {
  color: #a393ab;
  text-decoration: underline;
  font-size: 14px;
}

.tooltip-close {
  margin-left: auto;
  padding: 0 5px;
  cursor: pointer;
}

.work {
  max-width: 300px;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.work-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
