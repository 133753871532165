<template>
  <div>
    <h2>Nodes</h2>
    <ul>
      <li v-for="node in nodes" :key="node.id">
        <router-link :to="{ name: 'NodeDetails', params: { id: node.id }}">{{ node.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NodesList',
  data() {
    return {
      nodes: [],
    };
  },
  mounted() {
    axios.get('/api/nodes/')
      .then(response => {
        this.nodes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>

