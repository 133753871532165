<template>
  <div v-if="work">
    <h2>{{ work.title }}</h2>
    <p>{{ work.description }}</p>
    <!-- Add more details as needed -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WorkDetails',
  data() {
    return {
      work: null,
    };
  },
  mounted() {
    axios.get(`/api/works/${this.$route.params.id}/`)
      .then(response => {
        this.work = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>

